import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from '../../components/seo'

const BlogPage = ({ data }) => (
  <Layout pageTitle={data.microcmsBlog.title}>
    <h1>{data.microcmsBlog.title}</h1>
    <div
      dangerouslySetInnerHTML={{
        __html: `${data.microcmsBlog.body}`,
      }}
    />
  </Layout>
)

export const Head = () => <Seo title="My Blog Posts" />

export default BlogPage

export const query = graphql`
  query($id: String!) {
    microcmsBlog(id: { eq: $id }) {
      blogId
      title
      body
    }
  }
`
